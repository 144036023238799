<template>
  <div class="infos-page">
    <div class="page-infos cont" v-if="datas">
      <div class="name toUppercase" v-html="datas.titre"></div><span>Mode &Eacute;dition</span>
      <div class="flex-wrap">
        <article class="avis">
          <strong>Avis d'expert</strong>
          <div v-html="datas.avis"></div>
        </article>
        <article>
          <strong>Informations générales</strong>
          <div v-html="datas.acheteur"></div>
        </article>
        <article>
          <strong>DCE :</strong>
          <div>
            {{ datas.linkDCE }} <br /><a
              @click="sendEmail(DCEstr)"
              class="button"
              >Demandez nous le DCE</a
            >
            <a class="button" v-if="datas.document" :href="datas.document" target="_blank">Télécharger le règlement de consultation</a>
          </div>
        </article>

        <article>
          <strong>Contact et correspondance acheteur :</strong>
          <div v-html="datas.contact"></div>
          <textarea name="" id=""></textarea>
        </article>
        <article>
          <strong>Date de dépôt exacte :</strong>
          <div v-html="datas.limite"></div>
        </article>
        <article>
          <strong>Période d'exécution du marché :</strong>
          <div v-html="datas.period"></div>
        </article>
        <article>
          <strong>Contenu / lots, Modalités :</strong>
          <div v-html="datas.contenu"></div>
        </article>
        <article>
          <strong>Zone géographique / Entité(s) réseau ciblé :</strong>
          <div v-html="datas.cibleZoneGeo"></div>
        </article>
        <article>
          <strong>Volume, Durée et Budget du marché :</strong>
          <div v-html="datas.volume"></div>
        </article>
        <article>
          <strong>Descriptif - Attendus / objectifs / planning :</strong>
          <div v-html="datas.descriptif"></div>
        </article>
        <article>
          <strong>Critères Acheteur :</strong>
          <div v-html="datas.criteres"></div>
        </article>
      </div>
    </div>
    <div v-else>Aucun marché correspondant</div>
    <a class="button assistance util" v-if="emailSent">Demande envoyée</a>
    <a class="button assistance" v-else @click="sendEmail(assistanceStr)"
      >Demander une assistance</a
    >
  </div>
</template>

<script>
import router from "@/router";

export default {
  data() {
    return {
      emailSent: false,
      opportunities: [
        {
          acheteur:
            "Acheteur : lel SMICTOM Lot Garonne Baise<br>Adresse : 16 route de Saint Léon – ZAE de la Confluence, 47160 DAMAZAN<br>Contact : Non spécifié directement, mais communication possible via le site de profil acheteur.", //QUI
          titre:
            "Accord-cadre à bons de commande relatif à la fourniture de kits de compostage individuel et composteurs bois pour les plateformes de compostage partagé.", //QUOI
          lienDCE:
            "Les documents de la consultation sont accessibles sur le site de dématérialisation du profil d'acheteur.", //lien de l'acheteur pour télécharger le DCE
          contact:
            "Les informations spécifiques de contact ne sont pas directement mentionnées. Les correspondances peuvent être adressées via la plateforme de dématérialisation.", //Contact et correspondance acheteur
          limite: "Date limite de réception des offres : 19 août 2024 à 12h00", //Date de dépôt exacte(limite)
          period:
            "L'accord-cadre est conclu pour une durée initiale de 12 mois, avec possibilité de reconduction trois fois pour un an.", //Période d'execution du marché
          contenu:
            "Lot 1 : Fourniture de composteurs en plastique recyclé de 400 litres et bioseaux de 7 litres.<br>Lot 2 : Fourniture de composteurs bois de 800 litres.", //Contenu / lots / Modalités
          cibleZoneGeo:
            "La fourniture sera destinée aux utilisateurs au sein du territoire du SMICTOM Lot Garonne Baise.<br>", //Zone géographique/ Entité(s) réseau ciblé
          volume:
            "Lot 1 : Jusqu'à 150 000 euros HT par an.<br> Lot 2 : Jusqu'à 20 000 euros HT par an.", //Volume //Budget du marché
          descriptif:
            "Le marché vise à fournir des composteurs adaptés à la gestion des déchets organiques, contribuant ainsi à la réduction des déchets et à la promotion du compostage au sein de la communauté.", //Descriptif -Attendus / objectifs / planning
          criteres:
            "Prix des prestations (50%)<br>Valeur technique de l'offre (40%)<br>Critère environnemental (10%), comprenant la réparabilité et les conditions de production des composteurs.", //Critères Acheteur
          avis: "Good lala", //avis d'expert,
          echantillon: "",
          visiteObligatoire: "",
          budget: "120000",
        },
      ],
    };
  },

  computed: {
    // mailtoStr(){
    //   return "mailto:contact@g-m-e.fr?subject=Demande d'assistance'&body=Bonjour, je souhaiterais obtenir une assistance pour l'appel d'offre %0D%0A" + this.datas.titre + '%0D%0A'
    // },
    // mailtoStrDce(){
    //   return "mailto:contact@g-m-e.fr?subject=Demande d'assistance'&body=Bonjour, je souhaiterais obtenir les documents (DCE, RC, ...) pour l'appel d'offre %0D%0A" + this.datas.titre + '%0D%0A'
    // },
    assistanceStr() {
      return (
        "Bonjour, je souhaiterais obtenir une assistance pour l'appel d'offre : " +
        this.market.titre +
        "  Prénom : " +
        this.$store.state.userInfos.prenom +
        " Nom : " +
        this.$store.state.userInfos.nom +
        " Téléphone : " +
        this.$store.state.userInfos.telephone +
        " Email : " +
        this.$store.state.userInfos.email +
        " Société : " +
        this.$store.state.userInfos.company
      );
    },
    DCEstr() {
      return (
        "Bonjour, je souhaiterais obtenir le DCE pour l'appel d'offre : " +
        this.market.titre +
        "  Prénom : " +
        this.$store.state.userInfos.prenom +
        " Nom : " +
        this.$store.state.userInfos.nom +
        " Téléphone : " +
        this.$store.state.userInfos.telephone +
        " Email : " +
        this.$store.state.userInfos.email +
        " Société : " +
        this.$store.state.userInfos.company
      );
    },
    datas() {
      return this.$store.getters.getMarketFromID(this.$route.params.id);
    },
    sortedContactList() {
      const data = [...this.contactListfromStore];
      data.sort((a, b) => a.name.localeCompare(b.name));

      return data;
    },
    userRole() {
      return this.$store.state.stripeRole;
    },
  },
  methods: {
    navigate(target) {
      router.push(target);
    },
    sendEmail(str) {
      const emailData = {
        to: "contact@g-m-e.fr",
        subject: "AO Sourcing - Demande pour : " + this.market.titre,
        body: str,
        from: "contact@g-m-e.fr",
      };

      fetch("https://sourcing-ao.fr/sendEmail.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            this.emailSent = true;
          } else {
            console.log("fail");
            alert(
              "Erreur lors de l'envoi de l'email. Merci de nous contacter directement"
            );
          }
        })
        .catch((error) => {
          console.error("Erreur:", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
article {
  width: 29%;
    padding: 10px;
    margin: 3px 1%;
    border-left: 3px solid #f3f3f3;
    border-bottom: 3px solid #f5f5f5;
    border-radius: 15px;

  &.avis {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}
.bright {
  color: #e1e1e1;
  font-size: 3em;
  margin: 4px;
  text-align: center;
}
.name {
  margin: 0 0 13px;
  padding: 10px;
  font-size: 1rem;
  font-weight: bold;
  color: #3c72d3;
  text-align: center;
}
strong {
  display: block;
    margin: 10px 0 0;
    color: #6c52cd;
    padding: 5px 0 5px 10px;
    text-transform: uppercase;
}
strong + div {
  padding: 0 10px;
}
.assistance {
  padding: 7px 12px;
    line-height: 2rem;
    text-align: center;
    height: 2rem;
    margin: 46px auto 20px;
    width: 190px;
    display: block;
    background: #ff8f00;
    font-weight: bold;
    font-size: 15px;
    text-decoration: none;

  &.util {
    background: #91c591;
  }
}
.infos-page {
  padding: 25px 0;
    background: white;
    border-radius: 7px;
    box-shadow: 0 0 28px -24px black;
    margin-top: 21px;
}
@media (min-width: 450px) {
  .name {
    font-size: 1.2rem;
    font-weight: bold;
    padding: 4px 17px 17px;
  }
}
.important {
  color: #ff5500;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
}
.toUppercase {
  text-transform: uppercase;
  font-weight: bold;
}
</style>
