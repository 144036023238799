<template>
  <div class="infos-page veille-page">
    <div class="page-infos cont" v-if="market">
      <div class="name toUppercase" v-html="market.titre"></div>
      
      <div class="flex-wrap">
        <article class="avis">
          <strong>Avis d'expert</strong>
          <div v-html="market.avis"></div>
        </article>
        <article>
          <strong>Informations générales</strong>
          <div v-html="market.acheteur"></div>
        </article>
        <article>
          <strong>DCE :</strong>
          <div>
            {{ market.linkDCE }} <br /><a
              @click="sendEmail(DCEstr)"
              class="button flat"
              >Demandez nous le DCE</a
            >
            <a class="button flat" v-if="market.document" :href="market.document" target="_blank">Télécharger le règlement de consultation</a>
          </div>
        </article>
        <article>
          <strong>Contact et correspondance acheteur :</strong>
          <div v-html="market.contact"></div>
        </article>
        <article>
          <strong>Date de dépôt exacte :</strong>
          <div v-html="market.limite"></div>
        </article>
        <article>
          <strong>Période d'exécution du marché :</strong>
          <div v-html="market.period"></div>
        </article>
        <article>
          <strong>Contenu / lots, Modalités :</strong>
          <div v-html="market.contenu"></div>
        </article>
        <article>
          <strong>Zone géographique / Réseau ciblé :</strong>
          <div v-html="market.cibleZoneGeo"></div>
        </article>
        <article>
          <strong>Volume, Durée et Budget du marché :</strong>
          <div v-html="market.volume"></div>
        </article>
        <article>
          <strong>Attendus / objectifs / planning :</strong>
          <div v-html="market.descriptif"></div>
        </article>
        <article>
          <strong>Critères Acheteur :</strong>
          <div v-html="market.criteres"></div>
        </article>
        <article>
          <div class="flex-wrap centered">
          <a class="button assistance util" v-if="market.isRequested">Demande envoyée</a>
          <a class="button assistance" v-else @click="sendEmail(assistanceStr)">Demander une assistance</a>
        </div>
        </article>
      </div>

      <div class="flex-wrap centered controls">
        <a class="button functionnal basic" @click="back()">Retour</a>
        <a class="button functionnal util" @click="unarchive()" v-if="market.archived && !isAdmin">Retirer de l'archive</a>
        <a class="button functionnal remove" @click="archive()" v-if="!market.archived && !isAdmin">Archiver</a>
      </div>
    </div>
    <div v-else>Aucun marché correspondant</div>
    <popinConfirmVue 
      v-if="popinShown" 
      @cancel="cancelPopin()"
      @confirm="confirmPopin()"
      :title="popinTitle" 
      :content="popinContent" 
      :label="popinLabel" 
      :cancellable="true">
    </popinConfirmVue>
  </div>
</template>

<script>
import router from "@/router";
import { getAuth } from "firebase/auth";
import PopinConfirmVue from '../utils/PopinConfirm.vue';
export default {
  data() {
    return {
      emailSent: false,
      popinShown:false,
      popinTitle:"Archiver ce marché ?",
      popinContent:"Il n'apparaîtra plus sur vos résultats de veille",
      popinLabel:"Label",
    };
  },
  components:{popinConfirmVue:PopinConfirmVue},
  computed: {
    assistanceStr() {
      return (
        "Bonjour, je souhaiterais obtenir une assistance pour l'appel d'offre : " +
        this.market.titre +
        "  Prénom : " +
        this.$store.state.userInfos.prenom +
        " Nom : " +
        this.$store.state.userInfos.nom +
        " Téléphone : " +
        this.$store.state.userInfos.telephone +
        " Email : " +
        this.$store.state.userInfos.email +
        " Société : " +
        this.$store.state.userInfos.company
      );
    },
    DCEstr() {
      return (
        "Bonjour, je souhaiterais obtenir le DCE pour l'appel d'offre : " +
        this.market.titre +
        "  Prénom : " +
        this.$store.state.userInfos.prenom +
        " Nom : " +
        this.$store.state.userInfos.nom +
        " Téléphone : " +
        this.$store.state.userInfos.telephone +
        " Email : " +
        this.$store.state.userInfos.email +
        " Société : " +
        this.$store.state.userInfos.company
      );
    },
    market() {
      return this.$store.getters.getMarketFromID(this.$route.params.id);
    },
    isAdmin() {
      return this.$route.params.isadmin;
    },
    sortedContactList() {
      const data = [...this.contactListfromStore];
      data.sort((a, b) => a.name.localeCompare(b.name));

      return data;
    },
    userRole() {
      return this.$store.state.stripeRole;
    },
  },
  methods: {
    back() {
      router.go(-1)
    },
    storeAssistRequest() {
      this.market.isRequested = true;
      this.$store.dispatch("saveMarketList", getAuth().currentUser.uid);
    },
    confirmPopin() {
      this.market.archived = true;
      this.$store.dispatch("saveMarketList", getAuth().currentUser.uid);
      router.go(-1)
    },
    unarchive() {
      this.market.archived = false;
      this.$store.dispatch("saveMarketList", getAuth().currentUser.uid);
    },
    archive() {
      this.popinShown=true
    },
    cancelPopin() {
      this.popinShown=false
    },
    navigate(target) {
      router.push(target);
    },
    sendEmail(str) {
      const emailData = {
        to: "contact@g-m-e.fr",
        subject: "AO Sourcing - Demande pour : " + this.market.titre,
        body: str,
        from: "contact@g-m-e.fr",
      };

      fetch("https://sourcing-ao.fr/sendEmail.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            this.emailSent = true;
            this.storeAssistRequest()
          } else {
            console.log("fail");
            alert(
              "Erreur lors de l'envoi de l'email. Merci de nous contacter directement"
            );
          }
        })
        .catch((error) => {
          console.error("Erreur:", error);
        });
    },
  },
};
</script>

<style lang="scss">
.veille-page {
  .controls {
    margin-top: 50px;
  }
}
article {
  width: 29%;
    padding: 10px;
    margin: 3px 1%;
    border-radius: 15px;
    box-shadow: -2px 3px 12px -12px black;

  &.avis {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}
.bright {
  color: #e1e1e1;
  font-size: 3em;
  margin: 4px;
  text-align: center;
}
.name {
  margin: 0 0 13px;
  padding: 10px;
  font-size: 1rem;
  font-weight: bold;
  color: #3c72d3;
  text-align: center;
}
article strong {
  display: block;
    margin: 10px 0 0;
    color: #6c52cd;
    padding: 5px 0 5px 10px;
    text-transform: uppercase;
}
strong + div {
  padding: 0 10px;
}
.assistance {
  padding: 7px 12px;
    line-height: 2rem;
    text-align: center;
    height: 2rem;
   
    width: 190px;
    display: block;
    background: #ff8f00;
    border-color: #ff8f00;
    font-weight: bold;
    font-size: 15px;
    text-decoration: none;



}
.infos-page {
  padding: 25px 0;
    background: white;
    border-radius: 7px;
    box-shadow: 0 0 28px -24px black;
    margin-top: 21px;
}
@media (min-width: 450px) {
  .name {
    font-size: 1.2rem;
    font-weight: bold;
    padding: 4px 17px 17px;
  }
}
.important {
  color: #ff5500;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
}
.toUppercase {
  text-transform: uppercase;
  font-weight: bold;
}
</style>
