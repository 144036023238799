<template>
  <div class="home">
    <h1>Développez vos opportunités, <br>Gagnez du temps</h1>
    <div class="presentation flex-wrap">
      <div class="flex">
        <div class="aligner svg">
          <font-awesome-icon icon="sync-alt" />
        </div>
        <p>
          Une plateforme intelligente pour trouver les marchés publics adaptés à
          votre expertise, sans effort.
        </p>
      </div>
      <div class="flex">
        <div class="aligner svg">
          <font-awesome-icon icon="bullseye" />
        </div>
        <p>
          Recevez chaque semaine les meilleures opportunités pour votre
          entreprise.
        </p>
      </div>
      <div class="flex">
        <div class="aligner svg">
          <font-awesome-icon icon="chart-line" />
        </div>
        <p>
          Maximisez votre potentiel de succès et gardez une longueur d'avance
          sur la concurrence.
        </p>
      </div>
    </div>
    <div class="ctas flex-wrap">
      <a
        href="mailto:contact@g-m-e.fr?subject=Demande de contact&body=Bonjour, je souhaiterais avoir plus d'informations. Nom, Prénom : Numéro de téléphone : "
        class="button cta"
      >
      Demander à être contacté
      <font-awesome-icon icon="phone" />
      </a>
      <button class="cta invert" @click="this.$router.push('/register')">
        Inscription<br> Deux semaines offertes
        <font-awesome-icon icon="arrow-right" />
      </button>
    </div>
  </div>
</template>

<script setup></script>
<style lang="scss">

.home {
  height: 80vh;
  background-position: center center;
  background-size: cover;
  margin: 0 auto;
  padding-top: 20px;

  @media (max-width: 450px) {
    height: 130vh;
  }

  h1 {
    color: #6589cb;
    font-size: 3.1em;
    text-align: center;
    text-transform: uppercase;
    @media (max-width: 450px) {
      font-size: 2.1em;
    }
  }
  h2 {
    padding: 20px;
    margin: 0;
    color: white;
    font-size: 2em;
    text-shadow: 0 2px 17px BLACK;
  }
  p {
    font-size: 1.3em;
    color: #3d90b1;
    padding-left: 10px;
  }
  .aligner {
    font-size: 2em;
    display: flex;
    justify-content: space-evenly;
    text-align: center;

    &.svg {
      svg {
        background: #3574e7;
        color: white;
        padding: 14px;
        border-radius: 60px;
        width: 30px;
        height: 30px;
      }
    }
  }
}
.presentation {
  justify-content: space-between;
  margin-top: 50px;
  .flex {
    width: 31%;
    padding: 0 1%;
    align-items: center;

    &:nth-child(2) {
      border: 1px solid #cdcdcd;
      border-top-width: 0;
      border-bottom-width: 0;
    }
    @media (max-width: 450px) {
      width: 100%;
    }
  }
}
.ctas {
  justify-content: center;
  margin-top: 50px;

  .cta {
    background: #ff8137;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-around;
    line-height: 1.3rem;
    min-width: 246px;
    margin-bottom: 15px;
    height: 70px;
    border-radius: 50px;

  &.invert {
    color: #ff8137;
    border: 1px solid #ff8137;
    background: white;
  }
}
}
</style>
