import { createRouter, createWebHashHistory } from "vue-router";
import PropertyForm from "@/components/property/PropertyForm";
import MarketList from "@/components/markets/MarketList";
import ClientsList from "@/components/markets/ClientsList";
import VeillePage from "@/components/markets/VeillePage";
import HomeView from "@/views/HomeView"
import VeillePageEditable from "@/components/markets/VeillePageEditable.vue";
//import PriceSelectionVue from "@/components/PriceSelection.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import( "../views/AboutView.vue"),
  },
  {
    path: "/properties",
    component: PropertyForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/clients-ad",
    component: ClientsList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/veille/:id/:isadmin?",
    component: VeillePage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/veille-edit/:id",
    component: VeillePageEditable,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/opportunites",
    component: MarketList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/register",
    component: () =>
      import( "@/views/RegisterForm.vue"),
  },
  {
    path: "/login",
    component: () =>
      import( "@/views/LoginPage.vue"),
  },
  {
    path: "/info",
    component: () =>
      import( "@/views/DashboardPage.vue"),
  },
  {
    path: "/dashboard",
    component: () =>
      import(
         "@/views/DashboardPage.vue"
      ),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/prices",
    component: () =>
      import( "@/components/PriceSelection.vue"),
    meta: {
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
export default router;
