<template>
  <div
    v-if='!isDateExpired(datas.limite)'
    class="list-item contact"
    @click="navigate(`${datas.id}`)"
  >

    <div v-if="admin" class="layer">
      <button @click.prevent="adminNavigate(`${datas.id}`)">Voir</button>
      <button @click.prevent="edit(`${datas.id}`)" class="util">&Eacute;diter</button>
    </div>

    <div class="company">{{ datas.nomAcheteur }}</div>
    <div class="name" v-html="datas.titre"></div>
    <div class="contact-infos">
      <div class="budget" v-if="datas.budget > 1">
        Budget <br /><strong>{{ datas.budget }} <span>€</span></strong>
      </div>
      <div class="budget" v-else>
        Budget <br /><strong>40000<span> € +</span></strong>
      </div>
      <div class="limit"  v-if='isDateExpired(datas.limite)'>
        Date limite <br /><strong>Expirée</strong>
      </div>
      <div class="limit" v-else>
        Date limite <br /><strong>{{ datas.limite }}</strong>
      </div>
    </div>
  </div>
  <div v-else @click="navigate(`${datas.id}`)" class="list-item contact">
    <div v-if="admin" class="layer">
      <button @click.prevent="adminNavigate(`${datas.id}`)">Voir</button>
      <button @click.prevent="edit(`${datas.id}`)" class="util">&Eacute;diter</button>
    </div>
    <div class="name" v-html="datas.titre"></div>
    <div class="limit"  v-if='isDateExpired(datas.limite)'>
        Date limite <br /><strong>{{ datas.limite }} (Expirée)</strong>
      </div>
  </div>
</template>
<script>
    import router from "@/router";
export default {
  props: {
    datas:{},
    admin: Boolean
  },
  methods: {
    navigate(target) {
      if(!this.admin){
        router.push('/veille/'+target);
      }
    },
    adminNavigate(target) {
      router.push('/veille/'+target+'/1');
    },
    edit(target) {
      this.$emit('editMarket',target)
    },
    isDateExpired(dateString) {
      // Convertir la date sous forme de texte en objet Date
      const dateParts = dateString.match(
        /(\d{1,2}) (\w+) (\d{4}) à (\d{2})h(\d{2})/
      );

      if (!dateParts) {
        console.error("Le format de date est incorrect");
        return null;
      }

      // Extraire les parties de la date
      const [, day, month, year, hours, minutes] = dateParts;

      // Associer les mois en français aux indices (octobre = 9, novembre = 10, etc.)
      const monthNames = [
        "janvier",
        "février",
        "mars",
        "avril",
        "mai",
        "juin",
        "juillet",
        "août",
        "septembre",
        "octobre",
        "novembre",
        "décembre",
      ];
      const monthIndex = monthNames.indexOf(month.toLowerCase());

      if (monthIndex === -1) {
        console.error("Le mois est incorrect");
        return null;
      }

      // Créer la date à partir des valeurs extraites
      const date = new Date(year, monthIndex, day, hours, minutes);

      // Comparer avec la date actuelle
      return date < new Date();
    },
  }
};
</script>
<style lang="scss" scoped>
  .list-item .layer {
    display: none;
  }
  .list-item:hover{
    .layer {
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      background: rgba(255, 255, 255, 0.527);
    }
  }
</style>
